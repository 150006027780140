<template>
  <ModalContent
    v-show="!isLoading"
    ref="case-access-control-content"
    name="case-access-control-content"
    title="Access and Assignment"
    submit-button-text="Save"
    width="1100px"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabledSubmit"
    @submit="onSubmit"
    @hidden="onContentHide"
  >
    <template
      v-if="Boolean(selectedEntityId)"
      #content
    >
      <entity-tool-bar
        :items="computedEntitiesList"
        :show-plus="false"
        :editable="false"
        show-case-entity
        readonly
        :loading="isLoading"
        class="mx-4 rounded-lg border"
        @select="handleEntityClick($event)"
      />

      <div v-if="isCaseEntity" class="flex items-center gap-3 w-full mx-4">
        <input
          v-model="propagateCaseAssignment"
          type="checkbox"
          :disabled="isLoading || disabledGroups || disabledUsers"
          id="propagateCaseAssignment"
          class="checkbox cursor-pointer checkbox-primary border-box-stroke checkbox-sm flex"
        />
        <label for="propagateCaseAssignment">These settings will apply to all the entities</label>
      </div>

      <div class="grid flex-1 grid-cols-2 space-x-6 overflow-y-auto max-w-full">
        <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full column">
          <ListView
            :disableCheckbox="disabledGroupsCheckbox"
            :disableToggle="disabledGroupsToggle"
            title="Groups that have access to the case"
            :listItems="filteredGroups"
            :showCheckBox="isCaseEntity"
            showSearch
            selectKey="has_access"
            toggleKey="assigned"
            searchRowLabel="Assign"
            class="h-full overflow-y-auto"
            @rowClick="selectCaseAccess($event, 'groups')"
            @handleFilterResult="handleFilterResult($event, 'groups')"
          />
        </div>

        <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full column">
          <ListView
            :disableCheckbox="disabledUsersCheckbox"
            :disableToggle="disabledUsersToggle"
            title="Users that have access to the case"
            :listItems="filteredUsers"
            :showCheckBox="isCaseEntity"
            showSearch
            valueKey="user_name"
            selectKey="has_access"
            toggleKey="assigned"
            searchRowLabel="Assign"
            class="h-full overflow-y-auto"
            @rowClick="selectCaseAccess($event, 'users')"
            @handleFilterResult="handleFilterResult($event, 'users')"
          />
        </div>
      </div>
    </template>
  </ModalContent>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/axios";
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/lists";
import EntityToolBar from "../components/EntityToolBar";
import { checkPermission } from "@shared/utils/functions";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import { PermissionError } from "@shared/utils/permissions";
import { mapCaseAccessData } from "../utils";

export default {
  name: "CaseAccessControlModal",

  components: {
    ModalContent,
    ListView,
    EntityToolBar,
  },

  props: {
    caseEntitiesData: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isOpened: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      searchValues: {
        users: "",
        groups: "",
      },
      caseAccessData: {
        users: [],
        groups: [],
      },
      userGroups: [],
      selectedEntityId: null,
      propagateCaseAssignment: false,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters(["getUserDetails", "getTenantId", "getClientId", "getCaseData", "getCurrentApp"]),

    filteredGroups(){
      return this.groups
        .filter(el => (el?.name?.toLowerCase()?.includes(this.searchValues.groups.toLowerCase())));
    },
    filteredUsers(){
      return this.users
        .filter(el => (el?.user_name?.toLowerCase()?.includes(this.searchValues.users.toLowerCase())));
    },
    // map data for the current entity to manage its has_assess/assigned properties:
    groups() {
      const list = [...this.caseAccessData.groups]
        .sort((a, b) => b.has_access - a.has_access);
      
      return list.map((row) => {
          const isAssigned = this.getIsAssigned(row, "groups");
          return {
            ...row,
            assigned: isAssigned,
            disabled: row.has_access && this.userGroups.includes(row.id)
          }
        });
    },
    // map data for the current entity to manage its has_assess/assigned properties:
    users() {
      const list = [...this.caseAccessData.users]
        .sort((a, b) => b.has_access - a.has_access);

      return list.map((row) => {
          const isAssigned = this.getIsAssigned(row, "users");
          return {
            ...row,
            assigned: isAssigned,
            disabled: row.has_access && row.id === this.getUserDetails.id
          }
        });
    },
    disabledGroupsToggle() {
      return this.disabledGroupsCheckbox || (!this.isCaseEntity && this.propagateCaseAssignment);
    },
    disabledGroupsCheckbox() {
      return this.isLoading || this.disabledGroups;
    },
    disabledUsersToggle() {
      return this.disabledUsersCheckbox || (!this.isCaseEntity && this.propagateCaseAssignment);
    },
    disabledUsersCheckbox() {
      return this.isLoading || this.disabledUsers;
    },
    disabledGroups() {
      return !this.checkPermission("case.assignment_update") && !this.checkPermission("groups.read");
    },
    disabledUsers() {
      return !this.checkPermission("case.assignment_update") && !this.checkPermission("users.read");
    },
    disabledSubmit() {
      return this.isLoading || (
        this.caseAccessData.groups.every(el => !el.has_access)
          && this.caseAccessData.users.every(el => !el.has_access)
      );
    },
    caseId() {
      return this.$route.query.caseid;
    },
    computedEntitiesList() {
      const { case_entities, case_entity_type_info } = this.caseEntitiesData;

      const list = this.items ?? case_entities;

      return list?.map(entity => ({
        id: entity.id,
        data: entity,
        collect_data: entity.collect_data,
        name: entity.entity_name,
        type: case_entity_type_info?.[entity.entity_type_id]?.name,
        selectable: true,
        readonly: true,
        disabled: false,
        selected: entity.id === this.selectedEntityId,
        checked: false,
        statusList: entity.statusList
      }));
    },
    isCaseEntity() {
      return this.selectedEntityId === this.caseEntity.id;
    },
    caseEntity() {
      return this.items?.find(item => item.entity_type === ENTITY_TYPE_NAMES.CASE) ?? {};
    },
    entityItemsIds() {
      return this.items
        ?.filter(item => item.entity_type !== ENTITY_TYPE_NAMES.CASE)
        ?.map(item => item?.id )?? [];
    },
  },

  watch: {
    async isOpened(val) {
      if (val) {
        this.isLoading = true;
        this.$emit("onLoadStart");
        this.propagateCaseAssignment = this.getCaseData.propagate_case_assignemnt;
        this.caseAccessData = await this.fetchData();
        this.userGroups = await this.getUserGroups();
        this.selectedEntityId = this.caseEntity.id;
        this.isLoading = false;
        this.$emit("onLoadEnd");
      }
    }
  },

  methods: {
    ...mapActions(["fetchCaseData"]),
    checkPermission,

    async getUserGroups() {
        this.isLoading = true;
        let url = this.getCurrentApp === 'client' ? `user/client/users`:  `user/tenant/users`;
        let items = [];
        try {
            let body = {
                tenant_id:  this.getCurrentApp === 'client' ? null : this.getTenantId,
                client_id:  this.getCurrentApp === 'client' ? this.getClientId : null,
                username: [this.getUserDetails.user_name]
            };
            let { data } = await axios.post(url, body);
            items = data.data?.[0]?.group_ids?.map((grp) => grp.group_id);
        } catch (error) {
            console.log(error, "<<<<error while fetching users");
        }
        this.isLoading = false;
        return items;
    },

    async fetchData() {
      const [userData, groupData] = await Promise.all([this.fetchUsers(), this.fetchGroups()]);

      return {
        users: mapCaseAccessData(userData ?? []),
        groups: mapCaseAccessData(groupData ?? []),
      };
    },

    async fetchUsers() {
      try {
        const { data } = await axios.get(`work-allocation/case/${this.caseId}/users`);
        return data;
      } catch (error) {
        console.error(error, "Error");
      }
    },

    async fetchGroups() {
      try {
        const { data } = await axios.get(`work-allocation/case/${this.caseId}/groups`);
        return data;
      } catch (error) { 
        console.error(error, "Error");
      }
    },

    getOriginalRow(row, type) {
      const originalRow = this.caseAccessData[type].find((el) => el.id === row.id);
      return originalRow ?? null;
    },

    getIsAssigned(row) {
      if (this.isCaseEntity) return row.assigned;
      else {
        const { assigned_entities } = row;

        return assigned_entities.some((el) => el === this.selectedEntityId);
      }
    },

    handleHasAccessChange({ row, value }, type) {
      // manage assignment settings in the original datasets:
      const originalRow = this.getOriginalRow(row, type);
      const prevVal = originalRow.has_access;
      const isChanged = prevVal !== value;
      if (!isChanged) return;
      const isPermitted = !row.assigned;
      // don't allow deselection if assigned to case:
      if (!value && !isPermitted) {
        this.$toast.error("This group has entity assignment");
        originalRow.has_access = prevVal;
      } else {
        originalRow.has_access = value;
      }
    },

    handleAssignedChange({ row, toggle }, type) {
      // manage assignment settings in the original datasets:
      const originalRow = this.getOriginalRow(row, type);
      const originalAssigned = this.getIsAssigned(originalRow, type);
      const isAssignedChanged = originalAssigned !== toggle;
      if (!isAssignedChanged) return;

      if (this.isCaseEntity) {

        const permission = "case.edit";
        const isPermitted = checkPermission(permission);
        if (!isPermitted) {
          const permissionError = new PermissionError(permission);
          this.$toast.error(permissionError.message);
          throw permissionError;
        } else {
          originalRow.assigned = toggle;
        }
      } else {
        const { assigned_entities } = originalRow;

        const assignedIndex = assigned_entities.findIndex((el) => el === this.selectedEntityId);
        if (assignedIndex >= 0) {
          assigned_entities.splice(assignedIndex, 1);
        } else {
          assigned_entities.push(this.selectedEntityId);
        }
      }
    },

    selectCaseAccess(event , type) {
      try {
        this.handleHasAccessChange(event, type);
        this.handleAssignedChange(event, type);
      } catch (error) {
        console.error("Error", error);
      }
    },

    handleFilterResult(event, type){
      this.searchValues[type] = event;
    },

    handleEntityClick(entity) {
      this.selectedEntityId = entity.id;
    },

    async onSubmit() {
      if (!this.checkPermission("case.assignment_update")) {
        return this.$toast.error("You do not have permission to edit case access.");
      }

      const payload = {
        propagate_case_assignemnt: this.propagateCaseAssignment,
        case_id: this.caseId,
        groups: {
          groups: this.caseAccessData.groups
            .filter(el => el.has_access)
            .map(el => {
              return {
                group_id: el.id,
                assigned: el.assigned,
                assigned_entity_ids: el.assigned_entities,
              }
            })
        },
        users: {
          users: this.caseAccessData.users
            .filter(el => el.has_access)
            .map(el => {
              return {
                user_id: el.id,
                assigned: el.assigned,
                assigned_entity_ids: el.assigned_entities,
              }
            })
        },
      };

      try {
        this.isLoading = true;
        this.$emit("onLoadStart");
        const { status } = await axios.post(`work-allocation/case`, payload);

        if (status === 200) {
          this.$toast.success("Success");
          await this.fetchCaseData(this.caseId);
          this.$emit("onSubmit");
          this.onContentHide();
        }
      } catch (error) {
        this.$toast.error("Something went wrong");
        console.error(error, "Error");
      } finally {
        this.isLoading = false;
        this.$emit("onLoadEnd");
      }
    },

    onContentHide() {
      this.searchValues.groups = "";
      this.searchValues.users = "";
    }
  }
}
</script>

<style lang="scss" scoped>
.column {
  min-height: 300px; max-height: 600px;
}
</style>
